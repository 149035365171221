import polyfill from 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import registerServiceWorker from './registerServiceWorker';
import { unregister } from "./registerServiceWorker";

import Routes from "./router";
import {Provider} from "react-redux";
import store from "./store";


ReactDOM.render(
    <Provider store={store}>
        <Routes/>
    </Provider>
    ,document.getElementById('root'));
// registerServiceWorker();
unregister()

